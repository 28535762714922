import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import moment from 'moment';
import 'moment/locale/ru';

interface IEventCard {
    item: {
        id: string;
        title: string;
        description: string;
        date_begin: string;
        date_end: string;
        is_active: boolean;
        image: string;
    };
}

export const EventCard: React.FC<IEventCard> = ({ item }) => {
    moment.locale('ru');
    let dateStart = moment.utc(item.date_begin).format('D MMMM, dd, HH:mm');
    let dateEnd = moment.utc(item.date_end).format('D MMMM, dd, HH:mm');
    const [isOnlyDay, setIsOnlyDay] = useState(false);

    useEffect(() => {
        const a = item.date_begin.slice(0, 10);
        const b = item.date_end.slice(0, 10);
        if (a === b) setIsOnlyDay(true);
    }, [item.date_begin, item.date_end]);

    return (
        <div className={styles.cardContainer}>
            <img src={item.image} alt={item.title} />
            <h4>{item.title}</h4>
            <p className={styles.text}>{item.description}</p>
            <p className={styles.date}>
                {dateStart}
                {!isOnlyDay && ` - ${dateEnd}`}
            </p>
        </div>
    );
};
