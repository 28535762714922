import { useEffect, useState } from 'react';
import { Events } from '../../api/events';
import { EventCard, Loader } from '../../components';
import styles from './style.module.scss';
import noRecords from '../../assets/image/noRecords.jpg';

interface IItem {
    id: string;
    title: string;
    description: string;
    date_begin: string;
    date_end: string;
    is_active: boolean;
    image: string;
}

export const MainPage = () => {
    const [events, setEvents] = useState<IItem[] | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(20);
    useEffect(() => {
        setTimeout(() => setIsLoading(false), 3000);
    }, []);
    useEffect(() => {
        try {
            (async () => {
                const events = await Events.get();
                const result = events.data;
                setTotal(events.data.total);
                setEvents(result);
            })();
        } catch (err) {
            console.error(err);
        }
    }, []);

    return (
        <>
            {events?.length ? (
                <div className={styles.mainBlock}>
                    <div className={styles.innerBox}>
                        <h1>Анонсы мероприятий</h1>
                        <div className={styles.loaderBox}>
                            {isLoading && <Loader />}
                        </div>
                        {events.map((item: IItem) => (
                            <div className={styles.eventCardBox}>
                                <EventCard key={item.id} item={item} />
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        backgroundImage: `url(${noRecords})`,
                    }}
                    className={styles.noRecordsImg}
                />
            )}
        </>
    );
};
