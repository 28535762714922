import axios from "axios";
import { links } from "./apiLinks";

export const Events = {
  get: () => axios.get(`${links.baseUrl}${links.events.get}`, {
    
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        console.log(
          "Events error > ",
          error.response.data
        );
      }
    })
}
